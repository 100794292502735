import React, { useState, useEffect, useRef } from "react";
import { FiSearch, FiShoppingCart, FiUser, FiHome, FiMenu, FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Logo from '../../asstes/paarampariyaa_logo.png';
import Spinner from "../Spinner";
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
const Header1 = () => {
  const [hoveredItem, setHoveredItem] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false); // New state for transition control
  const [showDropdown, setShowDropdown] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [navItems, setNavItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const modalRef = useRef(null);
  const [isGuest, setIsGuest] = useState(false);
  const { user } = useKindeAuth();
  const token = localStorage.getItem('authToken');


  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const dropdownRef = useRef(null);

  useEffect(() => {
    if (user) {
      setIsGuest(true)
    }
    else {
      setIsGuest(false)
    }
  }, []);

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setShowDropdown(false);
  //   }
  // };

  // useEffect(() => {
  //   // Bind the event listener
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     // Unbind the event listener on cleanup
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  useEffect(() => {
    const fetchCategoriesAndProducts = async () => {

      try {
        setIsLoading(true)
        const categoriesResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/categories`);

        const fetchedCategories = categoriesResponse.data.categories.map(category => ({
          id: category._id,
          name: category.title,
        }));

        const productsResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/products/all`);
        const allProducts = productsResponse.data.products;
        //sort allProducts by using the sortNumber property
        allProducts.sort((a, b) => a.sortNumber - b.sortNumber);
        const newNavItems = fetchedCategories.map(category => ({
          id: category.id,
          name: category.name,
          titles: allProducts
            .filter(product => product.category === category.id)
            .map(product => ({
              id: product._id,
              name: product.name,
            })),
        }));


        setNavItems(newNavItems);
      } catch (error) {
        console.error("Error fetching categories and products:", error);
      } finally {
        setIsLoading(false)
      }
    };

    const fetchCartItems = async () => {

      if (token) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/cart`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setCartItems(response.data.cart.products);

        } catch (error) {
          console.error('Error fetching cart items:', error);
        }
      } else {

        try {

          const cartItems = JSON.parse(localStorage.getItem('cartItems'));
          setCartItems(cartItems);
      
        } catch (error) {
          console.error('Error parsing cart items:', error);
        }

      }


    };


    fetchCartItems();
    fetchCategoriesAndProducts();
  }, []);


  const handleMenuToggle = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up
    setIsMenuOpen((prev) => !prev); // Toggle menu
    setSelectedCategory(null); // Reset selected category to ensure correct re-render
    setIsTransitioning(false); // Reset transition
  };

  const handleCategoryClickMobile = (category) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setSelectedCategory(category);
      setIsTransitioning(false);
    }, 500);
  };
  const handleLogout = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/logout`);
      localStorage.removeItem('email');
      localStorage.removeItem('userId');
      localStorage.removeItem('authToken');
      setEmail(null);
      setShowDropdown(false);
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
      toast.error('Failed to log out. Please try again.');
    }
  };

  const handleCategoryClick = (id, name) => {


    navigate(`/category/${id}`, { state: { categoryId: id} });
    setHoveredItem(true);
    setIsMenuOpen(false);

  };


  const handleTitleClick = (productId) => {
    navigate(`/product?productId=${encodeURIComponent(productId)}`);
    setIsMenuOpen(false);
    setHoveredItem(null);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'  // Optional: adds a smooth scroll effect
    });
  };
  // ... existing code ...



  const filteredProducts = allProducts.filter(product => product.category === hoveredItem);

  const dropdownStyles = {
    transition: 'opacity 10000ms ease-in-out, transform 10000ms ease-in-out',
    opacity: isDropdownVisible ? 1 : 0,
    transform: isDropdownVisible ? 'scale(1)' : 'scale(0.95)',
  };

  return (
    <header className="fixed top-0 left-0 w-full z-50">

      {isLoading ? (<Spinner />) : null}
      {/* Top Header Section */}
      <div className="flex flex-col md:flex-row text-white  ">
        {/* Store Text and Icons Section */}
        <div className="bg-[#181818] flex flex-col md:flex-row justify-between items-center w-full mt-0 md:px-[80px]">

          {/* Store Text on the left for large screens */}
          <div onClick={() => navigate('/store')} className="hidden md:flex flex-row items-center mb-2 md:mb-0 cursor-pointer">
            <FiHome className="h-5 w-5 mr-1" />
            <span className="font-medium text-2xl text-white mt-1">Store</span>
          </div>

          {/* Logo centered on all screens */}
          <div onClick={() => navigate('/')} className="flex justify-center w-full cursor-pointer">
            <img src={Logo} alt="Param Logo" className="h-[50px] md:h-[60px]" />
          </div>

          {/* Icons section on the right for large screens */}
          <div className="hidden md:flex flex-row space-x-4 items-center">
            {/* <FiShoppingCart onClick={() => navigate('/cart')} className="text-xl cursor-pointer" /> */}
            <div className="relative w-12 p-2">
              <FiShoppingCart onClick={() => navigate('/cart')} className="text-2xl cursor-pointer" />
              {cartItems?.length > 0 && (
                <div className="absolute -top-1 -right-2 bg-[#ECBC56] text-[#181818] rounded-full text-sm font-semibold w-5 h-5 flex items-center justify-center">
                  {cartItems?.length}
                </div>
              )}
            </div>
            {email ? (
              <div className="relative">
                <div ref={dropdownRef} className="relative">
                  <FiUser
                    className="text-2xl cursor-pointer"
                    onClick={() => setShowDropdown(!showDropdown)}
                  />
                  {showDropdown && (
                    <div className="absolute right-0 md:w-[120px] mt-4 w-[120px] bg-white text-black rounded-lg shadow-lg z-50">
                      <button
                        className="w-full px-4 py-2 text-sm cursor-pointer flex justify-center items-center hover:bg-gray-200 hover:rounded-t-lg"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                      <hr className="w-full h-[2px] bg-gray-200" />
                      <button
                        className="w-full px-4 py-2 text-sm cursor-pointer flex justify-center items-center mt-1 hover:bg-gray-200 hover:rounded-b-lg"
                        onClick={() => {
                          setShowDropdown(false);
                          navigate('/order');
                        }}
                      >
                        My Orders
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div ref={dropdownRef} className="relative">
                  <FiUser
                    className="text-2xl cursor-pointer"
                    onClick={() => setShowDropdown(!showDropdown)}
                  />
                  {showDropdown && (
                    <div className="absolute right-0 md:top-[30px] md:right-[0px] md:w-[120px] mt-4 w-[120px] bg-white text-black rounded-lg shadow-lg z-50">
                      <button
                        className="w-full px-4 py-2 text-sm cursor-pointer flex justify-center items-center hover:bg-gray-200 hover:rounded-t-lg"
                        onClick={() => {
                          setShowDropdown(false);
                          navigate('/login');
                        }}
                      >
                        Login
                      </button>
                      <hr className="w-full h-[2px] bg-gray-200" />
                      <button
                        className="w-full px-4 py-2 text-sm cursor-pointer flex justify-center items-center mt-1 hover:bg-gray-200 hover:rounded-b-lg"
                        onClick={() => {
                          setShowDropdown(false);
                          navigate('/order');
                        }}
                      >
                        My Orders
                      </button>
                    </div>
                  )}
                </div>
                {isGuest ? <h1 className="text-white z-10">{isGuest} Guest</h1> : null}

              </>
            )}
          </div>


          {/* Mobile layout */}
          <div className="p-2 bg-[#222222] flex justify-between w-full md:hidden">
            {/* Store text on the left for mobile */}
            <div onClick={() => navigate('/store')} className="flex flex-row items-center cursor-pointer">
              <FiHome className="h-6 w-6 mr-1" />
              <span className="font-medium text-lg  text-white mt-1">Store</span>
            </div>

            {/* Icons on the right for mobile */}
            <div className="flex flex-row space-x-4 items-center">
              <div className="relative w-12 p-2">
              <FiShoppingCart onClick={() => navigate('/cart')} className="text-2xl cursor-pointer" />
              {cartItems?.length > 0 && (
                <div className="absolute top-0 -right-2 bg-[#ECBC56] text-[#181818] rounded-full text-sm font-semibold w-5 h-5 flex items-center justify-center">
                  {cartItems?.length}
                </div>
              )}
            </div>
              {email ? (
                <div className="relative">
                  <FiUser className="text-2xl cursor-pointer" onClick={() => setShowDropdown(!showDropdown)} />
                  {showDropdown && (
                    <div className="absolute right-0   md:right-[0px] md:w-[120px] mt-4 w-[120px] bg-white text-black rounded-lg shadow-lg z-50">
                      <button className="w-full px-4 py-2 text-sm  cursor-pointer flex justify-center items-center  hover:bg-gray-200 hover:rounded-t-lg" onClick={handleLogout}>
                        Logout
                      </button>
                      <hr className="w-full h-[2px] bg-gray-200" />
                      <button
                        className=" w-full px-4 py-2 text-sm cursor-pointer flex justify-center items-center mt-1  hover:bg-gray-200 hover:rounded-b-lg"
                        onClick={() => {
                          setShowDropdown(false);
                          navigate('/order');
                        }}
                      >
                        My Orders
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div>
                    <FiUser className="text-2xl cursor-pointer" onClick={() => setShowDropdown(!showDropdown)} />

                  </div>
                  {showDropdown && (
                    <div className="absolute right-0  top-[100px] right-[11px] md:right-[60px] md:w-[120px] mt-4 w-[120px] bg-white text-black rounded-lg shadow-lg z-50">
                      <button className="w-full px-4 py-2 text-sm  cursor-pointer flex justify-center items-center  hover:bg-gray-200 hover:rounded-t-lg" onClick={() => {
                        setShowDropdown(false);
                        navigate('/login');
                      }}>
                        Login
                      </button>
                      <hr className="w-full h-[2px] bg-gray-200" />
                      <button
                        className=" w-full px-4 py-2 text-sm cursor-pointer flex justify-center items-center mt-1  hover:bg-gray-200 hover:rounded-b-lg"
                        onClick={() => {
                          setShowDropdown(false);
                          navigate('/order');
                        }}
                      >
                        My Orders
                      </button>
                    </div>
                  )}
                  {isGuest ? <h1 className="text-white z-10 ">{isGuest} Guest</h1> : null}</>
              )}
              <FiMenu className="text-2xl cursor-pointer" onClick={handleMenuToggle} />
            </div>
          </div>
        </div>
      </div>


      {/* Navigation Header */}

      <div className="relative" onMouseLeave={() => setHoveredItem(true)}>
        <nav className="bg-[#222222] text-white text-xl px-4 pt-0 md:pt-2  relative z-20">
          <div className="container mx-auto ">
            <ul className="hidden md:flex gap-2 text-[10px] md:text-[16px]  whitespace-nowrap justify-center">
              {navItems.map((item, index) => (
                <li
                  key={index}
                  className="relative group"
                  onMouseEnter={() => setHoveredItem(item.name)}
                  onMouseLeave={() => setHoveredItem(true)}
                >
                  <span
                    className="hover:text-yellow-500 cursor-pointer tracking-wider"
                    onClick={() => {
                      handleCategoryClick(item.id, item.name);
                      setIsMenuOpen(false); // Close dropdown when a category is clicked
                    }}
                  >
                    {item.name}
                  </span>

                  <div
                    className={`bg-[#222222] rounded-lg shadow-lg z-10 py-1 overflow-hidden transition-[max-height, opacity] duration-[2500ms] ease-in-out max-h-0 opacity-0 ${hoveredItem === item.name ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
                      }`}
                    style={{ width: "100%" }}
                  >
                    <div className="container mx-auto ">
                      <ul className="mt-8  flex flex-col text-white my-2 font-normal tracking-widest text-[20px]">
                        {item.titles && item.titles.length > 0 ? (
                          item.titles.map((title, titleIndex) => (
                            <li
                              key={titleIndex}
                              className="mb-2 hover:text-[#ECBC56] cursor-pointer"
                              onClick={() => handleTitleClick(title.id)}
                            >
                              {title.name}
                            </li>
                          ))
                        ) : (
                          <li className="text-gray-300">No items available</li>
                        )}
                      </ul>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </nav>

        {isMenuOpen && (
          <div
            ref={modalRef}
            className="absolute bg-[#222222] text-[16px] text-white w-full flex flex-col py-2 z-30 sm:hidden"
          >
            {selectedCategory ? (
              <div
                className={`transition-opacity duration-500 ease-in-out ${isTransitioning ? "opacity-0" : "opacity-100"
                  }`}
              >
                <div
                  className="flex items-center px-4 py-2 cursor-pointer"
                  onClick={() => {
                    setIsTransitioning(true);
                    setTimeout(() => {
                      setSelectedCategory(null); // Clear selected category on back
                      setIsTransitioning(false);
                    }, 500);
                  }}
                >
                  <FiArrowLeft className="mr-2" />
                </div>
                <div className="bg-[#222222] text-[20px] font-semibold pl-6">
                  {selectedCategory.titles.map((title, titleIndex) => (
                    <span
                      key={titleIndex}
                      className="block py-1 hover:bg-gray-700 tracking-wider cursor-pointer"
                      onClick={() => {
                        handleTitleClick(title.id);
                      }}
                    >
                      {title.name}
                    </span>
                  ))}
                </div>
              </div>
            ) : (
              navItems.map((item, index) => (
                <div key={index}>
                  <div
                    className={`px-4 py-2 hover:bg-gray-600 cursor-pointer transition-opacity tracking-wider duration-500 ease-in-out ${isTransitioning ? "opacity-0" : "opacity-100"
                      }`}
                    onClick={() => handleCategoryClickMobile(item)}
                  >
                    {item.name}
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </header>


  );
};

export default Header1;
