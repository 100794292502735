
import React, { useState ,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import CategorySection from '../Components/CategorySection';
import Footer from '../Components/Footer';
import Header1 from '../Components/Header/Header1';
import OurProducts from '../Components/Ourproducts';
import Testimonials from '../Components/Testimonial';
import WhyOurProduct from '../Components/WhyOurProduct';
import axios, { all } from "axios";
import Spinner from '../Components/Spinner';
const Categorypage = () => {
  // Define state for category name and description
  const [categoryName, setCategoryName] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");

  const { id } = useParams();
  console.log(id);


  //fecth category name and description using is
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/category/${id}`);
        const category = response.data.category;
        setCategoryName(category.title);
        setCategoryDescription(category.subtitle);
      } catch (error) {
        console.error('Error fetching category:', error);
      }
    };
    fetchCategory();
  })

 // Function to handle category click in Header1
 const handleCategoryClick = (name, description) => {
  setCategoryName(name); // Update category name based on the clicked category
  setCategoryDescription(description); // Update description based on clicked category
};

// Function to handle title click in Header1
const handleTitleClick = (name, description) => {
  setCategoryName(name); // Update category name based on the clicked title
  setCategoryDescription(description); // Update description based on clicked title
};
const [products, setProducts] = useState([]); // State to hold product data

useEffect(() => {
  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/products`);
      const allProducts = response.data.products;
      allProducts.sort((a, b) => a.sortNumber - b.sortNumber);
      setProducts(allProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  fetchProducts();
}, []);


  return (

    <div className='pt-[90px] md:pt-[100px]'>
          
      {/* Passing handleTitleClick to Header1 for updating category name and description */}
      <Header1 onTitleClick={handleTitleClick} onCategoryClick={handleCategoryClick}  />

      {/* Passing category name and description to CategorySection */}
      <CategorySection categoryName={categoryName} description={categoryDescription} />

      {/* Passing categoryName to OurProducts to display relevant products */}
      <OurProducts categoryName={categoryName} />

      {/* Other sections */}
      <WhyOurProduct />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default Categorypage;