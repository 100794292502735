import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import Spinner from './Spinner'; // Import the Spinner component

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Get the state passed from Header1
  const location = useLocation();
  const {id} = useParams();
  const selectedCategoryId = id;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/categories`);

        const fetchedCategories = categoriesResponse.data.categories.map(category => ({
          id: category._id,
          name: category.title,
          description: category.subtitle || "",
          images: category.images || []
        }));
        setCategories(fetchedCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError('Failed to fetch categories');
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) return <Spinner />; // Show spinner when loading
  if (error) return <p>{error}</p>;

  // Filter categories to display only the selected one
  const filteredCategory = categories.find(category => category.id === selectedCategoryId);

  return (
    <div className="bg-[#181818] w-full h-auto py-4">
      {filteredCategory ? (
        <div key={filteredCategory.id} className="w-full flex flex-col">
          <div className="w-full flex flex-col items-center md:flex-row text-white rounded-lg px-4 py-4 md:pl-20 md:pr-12 md:py-0">
            <p className="text-[#ECBE56] font-bold w-full text-[28px] md:text-[40px] md:-mt-2 px-[5px] md:px-0 ">
              {filteredCategory.name}
            </p>
            <p className="px-[12px] w-[360px] text-[15px] md:text-[20px] md:pb-[20px] md:pt-[-7px] mt-[12px] md:px-0 md:text-left">
              {filteredCategory.description}
            </p>
          </div>
          {filteredCategory.images.map(image => (
            <img key={image._id} src={image.url} alt={filteredCategory.title} className="object-cover md:h-[650px] h-[500px]" />
          ))}
        </div>
      ) : (
        <p className="text-xl p-4">No category selected</p>
      )}
    </div>
  );
};

export default CategoryList;
